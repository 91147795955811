<template>
  <div class="couriers">
    <Header title="Курьеры" />

    <div class="wrapper">
      <div class="container">
        <div class="salary__inner">
          <p class="courier__button" @click="addCourier">Добавить</p>
          <p class="courier__button" @click="refresh">Обновить</p>
        </div>
      </div>      
      <div class="order__detail" v-if="!isLoading">
        <div class="container">
          <ul>
            <li class="order__detail-item">
              <span class="order__detail-gray">ФИО</span>      
              <input type="text" v-model="search_courier" placeholder="Поиск курьера">        
            </li>
            <router-link tag="li" to="courier" class="order__detail-item" v-for="(courier, courierIndex) in filteredCouriers" :key="courierIndex" @click.native="getCourierCard(courier.Ссылка)">
              <div class="couriers-item">
                {{ courier.Наименование }}
                <img src="@/assets/img/arrow-right.png" alt="" />
              </div>
            </router-link> 
          </ul>         
        </div>  
      </div>
      <Loader v-else />
    </div>

		<popup :is-opened="openPopup" @close-popup="openPopup = false" :title="popupTitle" :text="popupText"></popup>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Popup from '@/components/Popup.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Couriers',
  components: {
    Header,
    Popup,
    Loader
  },
  data () {
    return {
      openPopup: false,
			popupTitle: '',
			popupText: '',
      isLoading: true,
      search_courier: ''
    }
  },
  computed: {
    couriers () {
      return this.$store.state.couriers ? this.$store.state.couriers : []
    }, 
    filteredCouriers () {
      return this.couriers.filter((el) => {
        if (this.search_courier === '') {
          return true
        } else {
          return el.Наименование.toLowerCase().indexOf(this.search_courier.toLowerCase()) > -1
        }
      })
    }
  },
  created () {
    this.refresh()
    this.$root.$on('popup', this.popupHandler)
  },
  methods: {
    popupHandler ({title,text}) {
			this.popupTitle = title
			this.popupText  = text
			this.openPopup  = true
		},
    addCourier () {
      this.$store.dispatch('CLEAR_CARD')
      this.$router.push('/courier')
      this.$store.dispatch('GET_NEW_CODE', '')
    },
    refresh () {
      this.isLoading = true
      this.$store.dispatch('CHECK_AUTH', localStorage.getItem('pin'))
        .then(
          (response) => {
            this.isLoading = false
            if (!this.$store.state.isAdmin) {
              this.$router.push('/')
            }
            if (response) {
              this.$store.dispatch('GET_COURIERS')
            } else if (response.status === 400) {
              this.$root.$emit('popup', {title: 'Ошибка', text: response.data.ОписаниеОшибки })
            }		
          },
          (err) => {
            this.isLoading = false
            throw new Error(err)             
          }
        )
        .catch (err => {
          this.isLoading = false
          this.$root.$emit('popup', {title: 'Ошибка', text: 'Ошибка загрузки'})
          // eslint-disable-next-line
          console.log(err)          
        })   
    },
    getCourierCard(link) {
      this.$store.dispatch('SET_COURIER_ID', link)
      this.$store.dispatch('GET_COURIER', link)
    }
  },
  filters: {
    time: value => {
      return !value ? '' : value.split('T')[1].slice(0, -3)
    },
    number: value => {
      return !value ? '' : value.split('-')[1]
    }
  }
}
</script>

<style lang="scss" scoped>
.couriers {
  background-color: #f2f2f2;
  min-height: 100vh;
  &-item {
    position: relative;
    padding-right: 16px;
    width: 100%;
    img {
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 0;
    }
  }
}
.salary {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 10px;
  }
}
.wrapper {
  width: 100%;
  margin: 0px auto;
  background-color: #fff;
}
.container {
  margin: 0 10px;
}
.courier {
  &__button {
    color: #11c10e;
  }
}
.order {
  &__detail {
    background-color: #f2f2f2;
    font-size: 14px;
    padding-bottom: 50px;
    &-item {
      padding: 12px 10px 10px;
      border-bottom: 1px solid #cacaca;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
      &--success {
        background-color: #cfe9d3;  
      } 
    }
    &-header {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-right {
      width: calc(100% - 70px);
      margin-left: 10px;
      &-inner {
        margin-right: 10px;
        width: 100%;
      }
      br {
        margin-top: 8px;
      }
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 16px;
      }
    }
    &-left {
      width: 50px;
      margin-right: 10px;
    }
    &-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-gray {
      color: #7e7e7e;
    }
    &-overall {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 10px 10px;
      border-top: 1px solid #000;   
      position: relative;
      top: -1px;   
    }
    &-sum {
      width: calc(100% - 86px);
      margin-right: 26px;
    }
  }
  &__all {
    color: #000;
    font-size: 16px;
    cursor: pointer;
    margin: 32px auto 0;
    display: block;
    background-color: $main;
    border-radius: 50px;
    padding: 6px 20px 9px;
    width: 120px;
  }
}
</style>
